import { graphql } from "gatsby";
import React from "react";
import { Categories } from "../components/Categories";
import Footer from "../components/footer";
import Navigation from "../components/navigation";


const BrunchBestellen = ({ data }) => {
    const imageSrc = data.wcProductsCategories.acf.hero_image_localFile.url;
    return (
        <>
            <Navigation noHero={false} />
            <div className="mt-12">
                <Categories data={data.wpProductCategory} />
            </div>
            <div className="px-8 py-12 mx-auto max-w-7xl">
                <img src={imageSrc} className="w-full h-auto" alt="" />
            </div>
            <Footer />
        </>
    );
};

export const query = graphql`
    query BrunchBestellen {
        wcProductsCategories(slug: { eq: "box" }) {
            acf {
                hero_image_localFile {
                  url
                }
            }
        }
        wpProductCategory(slug: { eq: "box" }) {
            id
            name
            slug
            description
            products {
                nodes {
                    ... on WpBundleProduct {
                        id
                        name
                        slug
                        description
                        price
                        image {
                            id
                            sourceUrl
                        }
                        metaData {
                            key
                            value
                        }
                        attributes {
                            nodes {
                                name
                                options
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default BrunchBestellen;

import React, { useState } from "react";
import { TProduct } from "../types/types";
import { classNames, formatMoney } from "../utils";
import Title from "./title";
import { navigate } from "gatsby";
import useCart from "../context/cart.context";
import Modal from "./modal";


interface CategoriesProps {
  data: any;
  setSelectedSlug?: (slug: string) => void;
  desc?: string;
}

export const Categories: React.FC<CategoriesProps> = ({ data, setSelectedSlug, desc }) => {
  const [highlightedRow, setHighlightedRow] = useState<number | string>(-1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const ref = React.createRef<HTMLButtonElement>();
  const { zipCode } = useCart();
  const { name, name: slug, products } = data;
  const sortedProducts = products.nodes.sort((a, b) => {
    if (a.name < b.name) {
      return 1;
    }
    if (a.name > b.name) {
      return -1;
    }
    return 0;
  });
  return (
    <div className="pt-20 pb-12 mx-auto mt-10 max-w-7xl">
      <Title title={name} breadcrumb={slug} subtitle={desc} />
      {/* Tiers */}
      <div className="mt-10 space-y-1 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-1">
        {sortedProducts
          .map((product: TProduct & { contents: string[] }) => {
            const metaDataSubtitle = product.metaData.find(
              (item) => item.key === "subtitle"
            );
            const metaDataSubtitleValue =
              metaDataSubtitle && metaDataSubtitle.value;
            return (
              <div
                key={JSON.stringify(product)}
                className="relative flex flex-col p-8 bg-white"
              >
                <div className="flex-1 pointer"  onClick={() => {
                  if (zipCode) {
                    navigate(
                      product.slug.startsWith("gutschein")
                        ? `/gutscheine/${product.slug}`
                        : `/lieferung/${product.slug}`
                    );
                  } else {
                    setSelectedSlug(
                      product.slug.startsWith("gutschein")
                        ? `/gutscheine/${product.slug}`
                        : `/lieferung/${product.slug}`
                    );
                    setModalOpen(true);
                  }
                }}>
                  {/*<a href={`/lieferung/${product.slug}`}>*/}
                    <figure className="w-full">
                      <img
                        className="mx-auto cursor-pointer"
                        alt={product.name}
                        src={
                          product.image?.sourceUrl
                            ? product.image.sourceUrl
                            : "https://ru06wamdpv.preview.infomaniak.website/wp-content/uploads/2021/04/bestellen.png"
                        }
                      />
                    </figure>

                    <h3 className="mt-4 font-serif text-3xl font-normal text-center text-gray-900 cursor-pointer">
                      {product.name}
                    </h3>
                    {metaDataSubtitleValue && (
                      <div className="mb-4 font-serif text-xl text-center text-gray-800 font-lighter">
                        {metaDataSubtitleValue}
                      </div>
                    )}

                    <div className="relative mt-2 mb-6">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t-2 border-black" />
                      </div>
                    </div>
                  {/*</a>*/}

                  {/* Feature list */}
                  <ul
                    className="mt-3 space-y-1"
                    onMouseOut={() => setHighlightedRow(-1)}
                  >
                    {product?.attributes?.nodes.map(
                      (featureGroup, groupIndex) => (
                        <div key={JSON.stringify(featureGroup)}>
                          {featureGroup.options.map((feature, featureIndex) => (
                            <li
                              key={groupIndex * 100 + featureIndex}
                              className={classNames(
                                "text-sm text-black flex",
                                featureIndex === (featureGroup as unknown as []).length - 1 &&
                                  groupIndex !== product.contents.length - 1
                                  ? "pb-8"
                                  : ""
                              )}
                              onMouseOver={() => {
                                setHighlightedRow(
                                  groupIndex * 100 + featureIndex
                                );
                              }}
                            >
                              <span
                                className={classNames(
                                  "p-1 w-full border-[2px] border-transparent",
                                  highlightedRow ===
                                    groupIndex * 100 + featureIndex
                                    ? "dotted-item"
                                    : ""
                                )}
                              >
                                {feature}
                              </span>
                            </li>
                          ))}
                        </div>
                      )
                    )}
                  </ul>
                </div>

                <div className="relative mt-4">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t-2 border-black" />
                  </div>
                </div>

                <div
                  onMouseOver={() => {
                    setHighlightedRow("price");
                  }}
                  onMouseOut={() => setHighlightedRow(-1)}
                  className={classNames(
                    "mt-9 py-1 text-center text-black border-[2px] border-transparent",
                    highlightedRow === "price" ? "dotted-item" : ""
                  )}
                >
                  {formatMoney(product.price as number)}
                </div>

                <a
                  href={`/lieferung/${product.slug}`}
                  onClick={(e) => {
                    e.preventDefault();
                    // zipCode
                    //   ? navigate(
                    //       product.slug.startsWith("gutschein")
                    //         ? `/gutscheine/${product.slug}`
                    //         : `/products/${product.slug}`
                    //     )
                    //   : setModalOpen(true);
                    if (zipCode) {
                      navigate(
                        product.slug.startsWith("gutschein")
                          ? `/gutscheine/${product.slug}`
                          : `/lieferung/${product.slug}`
                      );
                    } else {
                      setSelectedSlug(
                        product.slug.startsWith("gutschein")
                          ? `/gutscheine/${product.slug}`
                          : `/lieferung/${product.slug}`
                      );
                      setModalOpen(true);
                    }
                  }}
                  className="mt-6 mb-4"
                >
                  <span
                    className={
                      "bg-graniteGray text-white hover:bg-black block w-full py-3 px-6 border border-transparent text-center font-[400] text-sm uppercase"
                    }
                  >
                    Zur {product.name}
                  </span>
                </a>
              </div>
            );
          })
          .reverse()}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        pCancelButtonRef={ref}
      />
    </div>
  );
};
